import React from 'react'

/**
 * Display the default map
 *
 * @param {string} className
 */
const Map = props => {
  return (
    <div className={`map ${props.className}`}>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2382.421374784592!2d-6.355103983846385!3d53.335712879976796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670c962ef75e49%3A0xa4e2ebc00a5c69a0!2sStorageHeater.ie%20-%20Storage%20Heater%20Repair%20%26%20Upgrade!5e0!3m2!1sen!2sie!4v1614615009325!5m2!1sen!2sie"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default Map
