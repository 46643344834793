import React, { useState } from 'react'
import { Link } from 'gatsby'
import SiteMetadata from './SiteMetadata'
import logo from '../../static/media/logo.svg'
import MainMenu from '../data/menus.json'
import Phone from './shortcodes/Phone'
import NavbarStyles from '../styles/Navbar.module.sass'

/**
 * Display the global header
 */
const Navbar = () => {
  const { title, phone } = SiteMetadata()

  // Grab menu from JSON using this slug
  const menuUsedSlug = 'main'

  const [navbarState, setNavbarState] = useState(false)

  const toggleState = (newState = !navbarState) => {
    setNavbarState(newState)
  }

  return (
    <nav
      className={`navbar is-primary is-transparent ${NavbarStyles.navbar}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link
            to="/"
            className={`navbar-item ${NavbarStyles.logoHolder}`}
            title="Logo"
          >
            <img src={logo} alt={title} className={`${NavbarStyles.logo}`} />
          </Link>

          {/* Hamburger menu */}
          <span
            onClick={() => toggleState()}
            className={`navbar-burger burger ${navbarState ? 'is-active' : ''}`}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </span>

          {/* Mobile Call Button */}
          <a
            href={`tel:${phone}`}
            className={`mobile-phone-button is-hidden-tablet ${NavbarStyles.mobilePhoneButton}`}
          >
            Call
          </a>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${navbarState ? 'is-active' : ''} ${
            NavbarStyles.menu
          }`}
        >
          <div
            className={`navbar-start has-text-centered ${NavbarStyles.navbarStart}`}
          >
            {MainMenu.menus
              .find(menu => menu.title === menuUsedSlug)
              .links.map(({ text, link }) => (
                <Link
                  key={text}
                  to={link}
                  className="navbar-item"
                  activeClassName="is-active"
                >
                  {text}
                </Link>
              ))}
            <Phone class={`has-text-white with-icon ${NavbarStyles.phone}`} />
            <Link
              to="/contact/"
              className={`button is-link ${NavbarStyles.buttonGetCallout}`}
            >
              Get a Quote
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
