import React from 'react'
import { Helmet } from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'
import SiteMetadata from '../components/SiteMetadata'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import FloatingCTA from '../components/FloatingCTA'
import '../styles/_all.sass'

// Set up MDX Shortcodes
import Anchor from './shortcodes/Anchor'
import Button from './shortcodes/Button'
import CTA from './shortcodes/CTA'
import LargeCTA from './shortcodes/LargeCTA'
import Phone from './shortcodes/Phone'
import ServicePrices from './shortcodes/ServicePrices'

const shortcodes = {
  Anchor,
  Button,
  CTA,
  LargeCTA,
  Phone,
  ServicePrices,
}

/**
 * Construct the global layout
 *
 * @param {string} className
 */
const TemplateWrapper = props => {
  const { globalSchema } = SiteMetadata()

  return (
    <div>
      <Helmet>
        <html lang="en-gb" />
        <title>{props.seoTitle || props.title}</title>
        <meta name="description" content={props.seoDescription} />
        <script type="application/ld+json">{globalSchema}</script>
        <script type="application/ld+json">{props.seoSchema}</script>
      </Helmet>
      <Navbar />
      <div id="main" className={props.className}>
        <MDXProvider components={shortcodes}>{props.children}</MDXProvider>
      </div>
      <Footer />
      <FloatingCTA />
    </div>
  )
}

export default TemplateWrapper
