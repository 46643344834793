import React from 'react'

/**
 * Add an anchor link
 *
 * @param {string} id
 */
const Anchor = props => {
  return <span id={props.id} className="anchor"></span>
}

export default Anchor

/**
 * Create Netlify CMS Editor Component
 */
const AnchorEditorComponent = {
  // Internal id of the component
  id: 'anchor',
  // Visible label
  label: 'Anchor',
  // Fields the user need to fill out when adding an instance of the component
  fields: [{ name: 'id', label: 'ID', widget: 'string', default: '' }],
  // Pattern to identify a block as being an instance of this component
  pattern: /<Anchor id="(.*)" \/>/,
  // Function to extract data elements from the regexp match
  fromBlock: function(match) {
    return {
      id: match[1],
    }
  },
  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview: function(obj) {
    return <Anchor id={obj.id} />
  },
  // Function to create a text block from an instance of this component
  toBlock: function(obj) {
    return '<Anchor id="' + obj.id + '" />'
  },
}

export { AnchorEditorComponent }
